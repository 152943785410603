import React from 'react';
import './App.css'; // Ensure your CSS file is correctly imported
import NewsFeed from './NewsFeed'; // Adjust the path as necessary

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* You can put a header here */}
      </header>
      <NewsFeed />
    </div>
  );
}

export default App;
