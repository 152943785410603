import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSpring, animated } from 'react-spring';
import './NewsFeed.css'; // Make sure this is the correct path to your CSS file

const fetchNews = async () => {
  const API_URL = process.env.REACT_APP_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  try {
    const response = await axios.get(API_URL, {
      params: {
        fields: '["*"]',
        or_filters: '[["category", "=", "Top Stories"],["category", "=", "World News"],["status", "=", "published"]]',
        order_by: 'creation DESC',
        limit: 100
      },
      headers: {
        'Authorization': `Token ${API_KEY}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching news:", error);
    return [];
  }
};


const NewsCard = ({ news, swipe, handleReadMore }) => {
  const [readMoreClicked, setReadMoreClicked] = useState(false);
  const props = useSpring({ transform: `translateY(${swipe * 100}%)` });

  // Function to truncate the summary if it exceeds 60 words
  const truncateSummary = (summary) => {
    const words = summary.split(' ');
    if (words.length > 50) {
      return words.slice(0, 50).join(' ') + '...'; // Truncate and add ellipsis
    }
    return summary;
  };

  const handleClick = () => {
    if (!readMoreClicked) {
      handleReadMore();
    }
    setReadMoreClicked(true);
  };

  return (
    <animated.div className="news-item-container" style={props}>
        <div className="news-image-container" style={{ backgroundColor: `#${news.category_color}` }} data-category={news.category || 'World'}>
        <img src={news.image_url} alt={news.title} className="news-image" />
      </div>
      <h2 className="news-title">{news.title}</h2>
      <h3 className="news-summary">{truncateSummary(news.summary)}</h3>
      <p className="news-author">
      <img src="https://read.dailygram.news/favicon.ico" alt="Icon" style={{width: '15px', marginRight: '5px', verticalAlign: 'middle'}} /> 
      {`by ${news.author} via ${news.publisher}`}
    </p>
      <div className="news-footer-container" onClick={handleClick}>
        <div className="news-footer">
          <p>Read more at {news.publisher}</p>
        </div>
      </div>
    </animated.div>
  );
};

const NewsFeed = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [swipe, setSwipe] = useState(0);
  const [startY, setStartY] = useState(null);

  useEffect(() => {
    fetchNews().then(data => {
      setNewsItems(data);
    });
  }, []);

  useEffect(() => {
    const handleWheel = (event) => {
      if (event.deltaY > 0) {
        setSwipe(prevSwipe => Math.max(prevSwipe - 1, 0));
      } else {
        setSwipe(prevSwipe => Math.min(prevSwipe + 1, newsItems.length - 1));
      }
    };

    const handleTouchStart = (event) => {
      setStartY(event.touches[0].clientY);
    };

    const handleTouchEnd = (event) => {
      if (startY !== null) {
        const deltaY = event.changedTouches[0].clientY - startY;
        if (Math.abs(deltaY) > 50) {
          if (deltaY > 0) {
            setSwipe(prevSwipe => Math.max(prevSwipe - 1, 0));
          } else {
            setSwipe(prevSwipe => Math.min(prevSwipe + 1, newsItems.length - 1));
          }
        }
        setStartY(null);
      }
    };

    window.addEventListener('wheel', handleWheel);
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [newsItems, startY]);

  const handleReadMore = (sourceUrl) => {
    window.open(sourceUrl, '_blank');
  };

  return (
    <div className="news-feed">
      {newsItems.length > 0 ? (
        newsItems.map((news, index) => (
          <NewsCard key={index} news={news} swipe={index - swipe} handleReadMore={() => handleReadMore(news.source_url)} />
        ))
      ) : (
        <p>Loading news...</p>
      )}
    </div>
  );
};

export default NewsFeed;

